<template>
  <div class="w_auto">
    <!-- <BreadcrumbNav></BreadcrumbNav> -->
    <!-- 报名已结束 -->
    <div
      class="shadow radius5 pad20 mt15 bgwhite"
      style="display: flex; justify-content: flex-start"
    >
      <div class="chargeimg">
        <div class="coursedet-listimg">
          <img :src="detail.facePic" alt="">
        </div>
      </div>
      <div class="chargetxt">
        <h3>{{ detail.activityName }}</h3>
        <p class="line35">
          <span>最近在学：{{ statistic.joinNumber }}人</span>
          <span>累计报名：{{ statistic.enrollNumber }}人</span>
          <el-divider direction="vertical"></el-divider>
          <el-popover
            placement="bottom"
            width="300"
            trigger="hover"
            class="share"
          >
            <div>
              <el-divider>分享给朋友</el-divider>
              <el-row>
                <el-col :span="12">
                  <div>
                    <img
                      src="@/assets/images/onlinecourses/erweima.png"
                      alt=""
                    />
                  </div>
                </el-col>
                <el-col :span="12" style="line-height: 100px; display: flex">
                  <div v-for="(i, index) in shareImgList" :key="index">
                    <img
                      :src="i.img"
                      alt=""
                      style="cursor: pointer; padding-right: 5px"
                    />
                  </div>
                </el-col>
              </el-row>
            </div>
            <el-button type="text" icon="el-icon-share" slot="reference"
              >分享</el-button
            >
          </el-popover>
        </p>
        <p class="line35">讲师：{{ detail.teacherName }}</p>
        <p class="line35">
          培训时间：<span style="margin-right: 1px">{{
            detail.trainStartTime
          }}</span
          >~<span>{{ detail.trainEndTime }}</span>
        </p>
        <div>
          <el-row class="flex-between mt15">
            <el-col :span="24">
              <el-button
                v-if="detail.isJoin == 0"
                icon="el-icon-plus"
                type="primary"
                @click="signUpClick"
                >立即报名</el-button
              >
              <el-button v-if="detail.isJoin == 1" icon="el-icon-check"
                >已经报名</el-button
              >
              <el-button
                v-if="detail.activityState == 0"
                type="info"
                class="signed"
                disabled
                >课程未开始</el-button
              >
              <el-button
                v-if="detail.activityState == 2"
                type="info"
                class="signed"
                disabled
                >课程已结束</el-button
              >
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <!-- 课程详情 -->
    <div class="coursedetail mt15">
      <el-row :gutter="20">
        <el-col :span="16">
          <div>
            <div class="coursedet-jj pad20 bgwhite shadow mb15">
              <div class="coursedet-l-t">
                <img
                  style="line-height: 10px"
                  src="@/assets/images/onlinecourses/round.png"
                  alt=""
                />
                课程说明
              </div>
              <div class="coursedet-l-tw">
                <div
                  class="coursedet-l-twtxt"
                  v-html="detail.activityIntroduce"
                ></div>
              </div>
            </div>
            <div class="coursedet-cont bgwhite shadow pad20">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="目录" name="first">
                  <div class="collapse">
                    <el-collapse
                      v-model="activeNames"
                      v-for="(item, index) in stageList"
                      :key="index"
                    >
                      <el-collapse-item :name="index">
                        <template slot="title">
                          <i class="el-icon-collection"></i>{{ item.stageName }}
                        </template>
                        <div class="bgf4">
                          <div class="course-list activity-list">
                            <ul>
                              <li
                                class="bgf4"
                                v-for="(i, index) in item.subMap"
                                :key="index"
                              >
                                <div class="activityimg demo-image__error">
                                  <a href="javascript:;" class="course-tolearn" @click="directoryClick(i.courseId,i.activityId)"
                                    ><img :src="i.facePic" alt=""
                                  /></a>
                                </div>
                                <div class="activitytxt">
                                  <h4 @click="directoryClick(i.courseId,i.activityId)">
                                    <span style="cursor: pointer">{{
                                      i.courseName
                                    }}</span>
                                  </h4>
                                  <p>{{ i.courseDesc }}</p>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="评价" name="evaluation">
                  <div v-if="isAppraise == 0 && pingjia">
                    <div class="evaluation">
                      <div class="block">
                        <span class="demonstration">课程内容：</span>
                        <el-rate v-model="evaluationForm.content"  @change="evaluationInput"></el-rate>
                      </div>
                      <div class="block">
                        <span class="demonstration">讲师评价：</span>
                        <el-rate v-model="evaluationForm.lecturer"  @change="evaluationInput"></el-rate>
                      </div>
                      <div class="block">
                        <span class="demonstration">学习评价：</span>
                        <el-rate v-model="evaluationForm.learning"  @change="evaluationInput"></el-rate>
                      </div>
                    </div>
                    <div class="comment-form" style="display: flex">
                      <div v-if="value && value.headPortrait" style="margin-right: 2%">
                        <el-avatar :src="value.headPortrait"></el-avatar>
                      </div>
                      <div v-else style="margin-right: 2%">
                        <el-avatar size="large" :src="circleUrl"></el-avatar>
                      </div>
                      <el-input
                        style="margin-right: 2%"
                        v-model="evaluationForm.input"
                        placeholder="请输入内容"
                        @input="evaluationInput"
                      ></el-input>
                      <el-button type="success" :disabled="evaluationdis" @click="pingjiaClick">评价</el-button>
                    </div>
                  </div>
                  <div v-else>
                    <div
                      class="onehf"
                      v-for="(i, index) in appraisePageApi"
                      :key="index"
                    >
                      <div class="evaluation" style="padding: 0 30px">
                        <div class="block">
                          <span class="demonstration">课程内容：</span>
                          <el-rate v-model="evaluationForm.content"></el-rate>
                        </div>
                        <div class="block">
                          <span class="demonstration">讲师评价：</span>
                          <el-rate v-model="evaluationForm.lecturer"></el-rate>
                        </div>
                        <div class="block">
                          <span class="demonstration">学习评价：</span>
                          <el-rate v-model="evaluationForm.learning"></el-rate>
                        </div>
                      </div>
                      <div class="userimg">
                        <a href="javascript:void();" style="padding-right:10px">
                        <img :src="i.headPortrait" alt="" />
                        </a>
                        <div style="width: 100%; padding-top: 10px">
                          <p
                            style="
                              display: flex;
                              justify-content: space-between;
                              margin-bottom: 3px;
                              color: #666;
                            "
                          >
                            {{ i.userName }}
                            <span style="font-size: 14px; margin: 0 2%">{{
                              i.createdTime
                            }}</span>
                          </p>
                          <div>{{ i.appraiseContent }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div>
            <div
              class="coursedet-r-person bgwhite shadow"
              v-for="(i, index) in TeacherApiList"
              :key="index"
            >
              <div class="userimg">
                <a href="javascript:void();">
                  <img :src="i.headPortrait" alt="" />
                </a>
              </div>
              <el-divider>
                <div><b class="bor"></b></div>
              </el-divider>
              <div class="coursedet-r-cont">
                <h3>{{ i.nickName }}</h3>
                <div class="detintro">
                  <span>教龄：{{ i.teachingYear }}年</span>
                  <el-divider direction="vertical"></el-divider>
                  <span>毕业院校：{{ i.schoolCollegeName }}</span>
                  <el-divider direction="vertical"></el-divider>
                  <span>课程数量：{{ i.coursePlayVolume }}</span>
                </div>
              </div>
            </div>
            <div class="coursedet-r-tj bgwhite pad10 shadow mt15">
              <div slot="header" class="clearfix">
                <i class="el-icon-caret-right"></i>
                <span>热门推荐</span>
              </div>
              <div class="divider">
                <el-divider></el-divider>
              </div>
              <div>
                <div v-if="activityPageList.length != 0">
                  <div
                    class="recommended"
                    v-for="(item, index) in activityPageList"
                    :key="index"
                  >
                    <img style="width: 50%" :src="item.facePic" alt="" />
                    <div style="width: 40%; margin-left: 3%; padding-top: 10px">
                      <p class="curriculum">{{ item.activityName }}</p>
                      <div style="padding-top: 30px" class="cktj">
                        <i class="el-icon-user"></i>
                        <span>{{ item.joinNumber }}人参加</span>
                      </div>
                    </div>
                  </div>
                </div>

                <el-empty v-else :image-size="150" description="暂无推荐" />
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
        <!-- 添加字段弹框 -->
      <el-dialog
        title="信息展示"
        :visible.sync="information"
        width="60vh"
        :close-on-click-modal="false"
        >
        <div>
          <el-form :model="examInfo" ref="examForms">
            <el-table :data="examInfo.data " style="width: 100%">
              <el-table-column prop="paramName" align="center" label="名称">
              </el-table-column>
              <el-table-column prop="paramVal" label="值" align="center">
              <template slot-scope="scope">
                <el-form-item
                  v-if="
                    scope.row.paramFormat == 1 && scope.row.isRequired == 1
                  "
                  :prop="'data.' + scope.$index + '.paramVal'"
                  :rules="[
                    {
                      required: true,
                      message: '数字不能为空',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="scope.row.paramVal"
                    maxlength="50"
                    type="number"
                    placeholder="请输入值"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  v-if="
                    scope.row.paramFormat == 0 && scope.row.isRequired == 1
                  "
                  :prop="'data.' + scope.$index + '.paramVal'"
                  :rules="[
                    {
                      required: true,
                      message: '内容不能为空',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    v-model="scope.row.paramVal"
                    maxlength="50"
                    placeholder="请输入值"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :prop="'data.' + scope.$index + '.paramVal'"
                  v-if="
                    1 == scope.row.paramFormat && scope.row.isRequired == 0
                  "
                >
                  <el-input
                    v-model="scope.row.paramVal"
                    maxlength="50"
                    type="number"
                    placeholder="请输入值"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :prop="'data.' + scope.$index + '.paramVal'"
                  v-if="
                    0 == scope.row.paramFormat && scope.row.isRequired == 0
                  "
                >
                  <el-input
                    v-model="scope.row.paramVal"
                    maxlength="50"
                    placeholder="请输入值"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            </el-table>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="informationSubment">确 定</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
// import BreadcrumbNav from '@/components/breadCrumbs/Breadcrumb'
export default {
  // components:{ BreadcrumbNav },
  data() {
    return {
      token: localStorage.getItem("pc_token"),
      value: JSON.parse(localStorage.getItem("pc_data")),
      detail: [],
      statistic: {},
      stageList: [],
      shareImgList: [
        { img: require("@/assets/images/onlinecourses/weibo.png") },
        { img: require("@/assets/images/onlinecourses/kongjian.png") },
        { img: require("@/assets/images/onlinecourses/dou.png") },
        { img: require("@/assets/images/onlinecourses/tie.png") },
      ],
      TeacherApiList: [],
      labelPageList: [],
      activityPageList: [],
      appraisePageApi: [],
      fieldList:[],
      examInfo: {},
      evaluationForm: {
        content: null,
        lecturer: null,
        learning: null,
        input: "",
      },
      activeName: "first",
      isAppraise: "",
      fieldStr: '',
      evaluationdis: true, //评价
      information: false, // 字段弹框
      pingjia: true,

      activeNames: ["1"],
    };
  },
  mounted() {
    this.getActivityDetailApi();
    this.getFrontActivityField();
    this.getFrontActivityTeacherApi();
    this.getFrontActivityLabelPageApi();
    this.getFrontActivityAppraisePageApi();
    this.getUserActivityIsAppraise();
  },
  methods: {
    //   报名按钮
    signUpClick(){
      if(this.detail.joinWay == 0){ //公开培训
      
        if(this.fieldList == ''){
          this.$confirm('确定报名该培训吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.activityEnroll()
                this.getActivityDetailApi();
            }).catch(() => {   
          })
        }else if(this.fieldList != ''){
          this.information = true
        }
      }else if(this.detail.joinWay == 1){ //报名培训
        if(this.fieldList == ''){
            
          this.$confirm('确定报名该培训吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {            
              this.activityEnroll()     
              this.getActivityDetailApi();  
            }).catch(() => { 
              this.$message({
              type: 'info',
              message: '已取消报名'
            });     
          })
        }else if(this.fieldList != ''){
          this.information = true
        }
      }
    },
    // 字段展示确定按钮
    informationSubment(){
      this.$refs.examForms.validate((valid) => {
        if(valid){
          this.information = false
          this.activityEnroll()
          this.getActivityDetailApi();
        }else{
          this.$message.warning('请填写必填项')
        }
      })
    },
    // 获取活动字段
    getFrontActivityField(){
        this.$api.getFrontActivityField({
            activityId:this.$route.query.activityId,
            token:this.token
        }).then(res => {
            if(res.code == 10000){
                this.fieldList = res.data
                this.examInfo = res
                console.log(this.examInfo);
            }
        })
    },
    inquiryGoodsStr(){
      console.log(this.fieldList);
      const List = this.fieldList.map((item) => {
        return {
          paramsId:item.paramsId,
          paramVal:item.paramVal,
          paramName:item.paramName,
          isRequired:item.isRequired,
          paramFormat:item.paramFormat,
          displaySort:item.displaySort,
        }
      })
      this.fieldStr = JSON.stringify(List)
    },
    // 报名接口
    activityEnroll(){
        this.inquiryGoodsStr()
        this.$api.activityEnroll({
            activityId:this.$route.query.activityId,
            fieldStr:this.fieldStr,
            token:this.token
        }).then(res => {
            if(res.code == 10000){
                this.$message.success('报名成功,等待审核')
            }
        }).catch((res) => {
          this.$message.warning(res.msg)
        })
    },
    //   详情接口
    getActivityDetailApi() {
      this.$api
        .getActivityDetailApi({
          activityId: this.$route.query.activityId,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.detail = res.data.detail;
            this.statistic = res.data.statistic;
            this.stageList = res.data.stageList;
          }
        });
    },
    // 讲师简介接口
    getFrontActivityTeacherApi() {
      this.$api
        .getFrontActivityTeacherApi({
          activityId: this.$route.query.activityId,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.TeacherApiList = res.data;
          }
        });
    },
    // 获取活动标签列表
    getFrontActivityLabelPageApi() {
      this.$api
        .getFrontActivityLabelPageApi({
          activityId: this.$route.query.activityId,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.labelPageList = res.data[0];
          }
        });
    },
    // 课程推荐列表
    getFrontLabelActivityPageApi() {
      this.$api
        .getFrontLabelActivityPageApi({
          channelId: this.$http.channelId,
          labelNum: this.labelPageList.labelNum,
          labelId: this.labelPageList.labelId,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.activityPageList = res.data;
          }
        });
    },
    // 获取学员是否评论
    getUserActivityIsAppraise() {
      this.$api
        .getUserActivityIsAppraise({
          activityId: this.$route.query.activityId,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.isAppraise = res.data;
          }
        });
    },
    // 获取活动评论信息
    getFrontActivityAppraisePageApi() {
      this.$api
        .getFrontActivityAppraisePageApi({
          page: 1,
          limit: 10,
          activityId: this.$route.query.activityId,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.appraisePageApi = res.data.data;
            this.appraisePageApi.forEach((item) => {
              this.evaluationForm.content = item.courseStar;
              this.evaluationForm.lecturer = item.lecturerStar;
              this.evaluationForm.learning = item.studyStar;
            });
          }
        });
    },
    evaluationInput() {
      if (
        this.evaluationForm.content != 0 &&
        this.evaluationForm.lecturer != 0 &&
        this.evaluationForm.learning != 0 &&
        this.evaluationForm.input != ""
      ) {
        this.evaluationdis = false;
      } else {
        this.evaluationdis = true;
      }
    },
    pingjiaClick(){
        if(this.token == null){
            this.$message.warning('请先登录')
            return
        }else if(this.detail.isJoin == 0){
            this.$message.warning('请先报名')
            return
        }else if(this.detail.activityState == 0){
            this.$message.warning('课程未开始')
            return
        }else if (this.detail.activityState == 2){
            this.$message.warning('课程已结束')
            return
        }else{
          this.addActivityAppraise()
        }
        
    },
    addActivityAppraise(){
        this.$api.addActivityAppraise({
            activityId:this.$route.query.activityId,
            appraiseContent:this.evaluationForm.input,
            courseStar:this.evaluationForm.content,
            studyStar:this.evaluationForm.learning,
            lecturerStar:this.evaluationForm.lecturer,
            token:this.token
        }).then(res => {
            if(res.code == 10000){
                this.$message.success('评价成功，等待审核')
                this.evaluationForm.input = ''
                this.pingjia = false;
                this.getFrontActivityAppraisePageApi()
            }
        })
    },
    // 目录跳转
    directoryClick(courseId,activityId){
        if(this.token == null){
          this.$message.warning('请先登录')
          return
        }else if(this.detail.isJoin == 0){
            this.$message.warning('请先报名')
            return
        }else if(this.detail.activityState == 0){
            this.$message.warning('课程未开始')
            return
        }else if (this.detail.activityState == 2){
            this.$message.warning('课程已结束')
            return
        }
        this.$router.push({
             path:'/course/detailList',
             query:{id:courseId,activityId:activityId}
        })
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style scoped lang="scss">
.coursedet-img {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.3);
  margin-top: 15px;
  display: inline-block;
  clear: both;

  .coursedet-bot {
    position: absolute;
    width: 100%;
    padding: 10px;
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    .coursedet-imgl p.coursedet-tit {
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .coursedet-imgl p.coursedet-tit em {
      margin-left: 10px;
      color: red;
    }
    .coursedet-imgl p {
      color: #e0e0e0;
      font-size: 14px;
    }
    .coursedet-imgl p span {
      margin-right: 25px;
    }
    .coursedet-imgbut button.signed {
      background: #333;
      color: #ccc;
      cursor: inherit;
    }
    .coursedet-imgbut button {
      color: #fff;
      padding: 10px 25px;
      font-size: 16px;
      border: 0 none;
    }
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }
}
.coursedet-listimg {
  width: 100%;
  height: 3.2rem;
  padding-bottom: 35%;
  overflow: hidden;
  background: url(../../assets/images/onlinecourses/datail.jpg) no-repeat;
  background-size: cover;
  background-position: 50%;
}
.coursedet-listimg img{
  width: 100%;
  height: 3.2rem;
}
.bgpng {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.chargetxt {
  width: 45%;
}
.chargetxt h3 {
  font-size: 22px;
  margin-bottom: 15px;
  font-weight: 700;
}
.line35 {
  line-height: 30px;
  color: #555;
  font-size: 15px;
}
.chargetxt .line35 span {
  margin-right: 25px;
}
.chargetxt p.label {
  padding: 10px 0;
}
.chargepay {
  display: flex;
  margin-top: 10px;
}
.flex-between {
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.bgf4 {
  background: #f4f3f3;
}
.pad5 {
  padding: 5px;
}
.red {
  display: inline-block;
  margin-top: 6px;
  color: red;
  font-size: 24px;
}
.radius5 {
  border-radius: 5px;
}
.flexstart {
  display: flex;
  justify-content: flex-start;
}
.pad20 {
  padding: 20px;
}
.shadow {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.bgwhite {
  display: inline-block;
  background-color: #fff;
  width: 100%;
}
.chargeimg {
  width: 55%;
  position: relative;
  margin-right: 25px;
}
.coursedetail {
  width: 100%;
  display: inline-block;
  clear: both;

  .mb15 {
    margin-bottom: 15px;
  }
  .coursedet-l-t {
    font-weight: 700;
    font-size: 16px;
    padding: 5px 0px 10px;
  }
  .coursedet-l-tw {
    display: flex;
    .coursedet-l-twtxt {
      padding: 0px 15px;
    }
  }
  .pad20 {
    padding: 20px;
  }
  .evaluation {
    display: flex;
    justify-content: space-between;
    .block {
      display: flex;
      .demonstration {
        color: #000;
        font-size: 14px;
      }
    }
  }
  .comment-form {
    max-width: 100%;
    padding: 0.1rem 0.1964rem;
    display: flex;
    background-color: rgb(250, 251, 252);
    border-radius: 3px;
    margin-top: 3%;
  }
  .coursedet-r-person {
    min-height: 20px;
    .avatar {
      text-align: center;
      padding: 30px 0px 5px;
    }
    .bor {
      position: absolute;
      width: 100px;
      height: 2px;
      left: 50%;
      margin-left: -50px;
      background-color: #3ce4bc;
    }
    .coursedet-r-cont h3 {
      text-align: center;
      color: #3ce4bc;
      font-size: 16.38px;
    }
    .coursedet-r-cont p {
      padding: 0px 10px 15px;
      font-size: 14px;
    }
    .detintro {
      text-align: center;
      padding: 15px 0px;
    }
    .detintro span {
      color: rgb(102, 102, 102);
      font-size: 14px;
    }
  }
  .pad10 {
    padding: 10px 5px;
  }
  .cktj {
    color: #000;
    font-size: 12px;
  }
  .activity-list li {
    display: flex;
    justify-content: flex-start;
    align-items: normal;
    border-bottom: none;
    padding: 15px 20px;
    font-size: 16px;
  }
  .activityimg {
    width: 240px;
    height: 140px;
    margin-right: 10px;
  }
  .course-tolearn {
    width: 240px;
    height: 140px;
    display: inline-block;
    border-radius: 5px;
    font-size: 14px;
    line-height: 30px;
    color: #3ce4bc;
  }
  .activityimg img {
    width: 240px;
    height: 140px;
  }
  .activitytxt {
    width: 100%;
  }
  .activitytxt h4 {
    margin-bottom: 10px;
  }
  .activitytxt h4 span:hover{
      color: #3ce4bc;
  }
  .activitytxt p {
    color: #666;
    line-height: 25px;
    font-size: 14px;
  }
  .userimg {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .userimg img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: url(../../assets/images/onlinecourses/avatar.png) no-repeat;
    background-size: 48px 48px;
    margin-top: 15px;
  }

  .curriculum {
    color: #333;
    font-size: 14px;
    cursor: pointer;
  }
  .curriculum:hover {
    color: #3ce4bc;
  }
}
.mt15 {
  margin-top: 15px;
}
.coursedet-r-tj {
  margin-bottom: 12%;
}
.recommended {
  display: flex;
  border-bottom: 1px dashed #ccc;
  padding: 10px;
}
.recommended:last-child {
  border: none;
}

.onehf {
    padding: 15px 10px 10px;
    background: #fcfcfc;
    display: flex;
    flex-direction: column;
    .evaluation {
    display: flex;
    justify-content: space-between;
    .block {
      display: flex;
      .demonstration {
        color: #000;
        font-size: 14px;
      }
    }
  }
}
.clearfix ::v-deep .el-icon-caret-right {
  font-size: 25px;
}
.divider::v-deep .el-divider--horizontal {
  margin: 5px 0;
}
.collapse::v-deep .el-collapse {
  border-top: 0px solid #ebeef5;
  border-bottom: 0px solid #ebeef5;
}
.collapse::v-deep .el-collapse-item__header {
  font-size: 14px;
  font-weight: 700;
}
.share::v-deep .el-button--text {
  color: #333;
}
</style>